import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { ButtonModule } from 'primeng/button';
import { Subscription } from 'rxjs';
import { TableComponent } from '@shared/components/table/table.component';
import { TableActions, TableSignals } from '@shared/interfaces/table';
import { get } from 'lodash';
import { ClaimsTableConfig } from '@constants/claimsTableConfig.const';
import { CommonModule } from '@angular/common';
import { ClaimDetailResponse } from '@shared/interfaces/claim.interface';
import { PermissionService } from '@Services/permission/permission.service';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { DeleteDialogService } from '@Services/dialogs/delete-dialog/delete-dialog.service';

/**
 * Represents the component for displaying a list of claims.
 */
@Component({
  selector: 'app-claim-list',
  standalone: true,
  imports: [PageTitleComponent, ButtonModule, TableComponent, CommonModule],
  templateUrl: './claim-list.component.html',
  styleUrl: './claim-list.component.scss',
})
export class ClaimListComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) claimTableComponent!: TableComponent<ClaimDetailResponse>;

  @Input() isGlobalClaimsList = false;
  @Input() claimsTableConfig = { ...ClaimsTableConfig };

  projectId: string | null = null;

  subscription: Subscription;

  claimsCount: number;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly permissionService: PermissionService,
    private readonly deleteDialogService: DeleteDialogService,
  ) {
    this.subscription = new Subscription();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    if (!this.isGlobalClaimsList) {
      this.projectId = get(this.activatedRoute, 'snapshot.parent.params.id');
      this.setTableEndPoint();
    }
  }

  /**
   * Sets the endpoint for the table.
   */
  setTableEndPoint(): void {
    this.claimsTableConfig.endPoint = `assetmanagement/projects/${this.projectId}/claims/`;
  }

  /**
   * Navigates to the create claim page.
   */
  navigateCreateClaim(): void {
    // navigate to create claim page
    this.router.navigate([`/project/${this.projectId}/claim/create`]);
  }

  /**
   * Handles the signals emitted by the table.
   * @param event The table signals event.
   */
  handleTableSignals(event: TableSignals<ClaimDetailResponse>): void {
    if (this.isGlobalClaimsList) {
      this.handleGlobalTableSignals(event);
      return;
    }
    const data = event.data;
    if (event.action === TableActions.openEditMode) {
      this.router.navigate([`edit/${data.id}`], { relativeTo: this.activatedRoute });
    } else if (event.action === TableActions.viewProjectDetails) {
      this.router.navigate([`details/${data.id}`], { relativeTo: this.activatedRoute });
    } else if (event.action === TableActions.deleteItem) {
      this.openClaimDeleteDialog(data);
    }
  }

  handleGlobalTableSignals(event: TableSignals<ClaimDetailResponse>): void {
    const data = event.data;
    if (event.action === TableActions.openEditMode) {
      this.router.navigateByUrl(`/project/${data.project.id}/claim/edit/${data.id}`);
    } else if (event.action === TableActions.viewProjectDetails) {
      this.router.navigateByUrl(`/project/${data.project.id}/claim/details/${data.id}`);
    } else if (event.action === TableActions.deleteItem) {
      this.openClaimDeleteDialog(data);
    }
  }

  openClaimDeleteDialog(claimDetails: ClaimDetailResponse) {
    if (!this.projectId) {
      this.projectId = claimDetails?.project?.id;
    }
    this.deleteDialogService.openDeleteDialog(
      {
        headerText: 'Delete Claim',
        name: claimDetails.code,
        description: 'Please provide a reason for deleting claim',
        path: `assetmanagement/projects/${this.projectId}/claims/${claimDetails.id}`,
      },
      'claim',
      () => {
        this.claimTableComponent.reloadCurrentPage();
      },
    );
  }

  /**
   * Sets the total count of claims.
   * @param count The total count of claims.
   */
  setTotalCount(count: number): void {
    this.claimsCount = count;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Checks if the user has permission to view claims.
   * @returns True if the user has permission to view claims, false otherwise.
   */
  get hasClaimViewPermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.VIEW_CLAIM);
  }

  /**
   * Checks if the user has permission to create claims.
   * @returns True if the user has permission to create claims, false otherwise.
   */
  get hasCreateClaimPermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.ADD_CLAIM);
  }

  get emptyStateDescription(): string {
    const isEmptyCustomFilter =
      Object.keys(this.claimTableComponent?.customFilterData).length === 0 &&
      this.claimTableComponent?.customFilterData?.constructor === Object;
    if (this.claimTableComponent?.filtersData || !isEmptyCustomFilter) {
      return 'No search results found with these filters.';
    } else {
      return 'There are no claims added';
    }
  }
}
