<div class="claim-list">
  <div
    class="header-container d-flex justify-content-space-between align-items-end title-container mb-20"
  >
    <div class="mt-20">
      <app-page-title title="Claims" fontWeight="600" />
    </div>
    <div>
      @if (hasCreateClaimPermission && !isGlobalClaimsList) {
        <p-button
          class="primary-color add-task"
          type="button"
          class="add-task"
          icon="pi pi-plus"
          label="Add Claim"
          [outlined]="true"
          size="small"
          (onClick)="navigateCreateClaim()"
          (keyup.enter)="navigateCreateClaim()"
          tabindex="0"
        />
      }
    </div>
  </div>
  @if (hasClaimViewPermission) {
    <ng-container>
      <app-table
        [tableConfig]="claimsTableConfig"
        (tableSignals)="handleTableSignals($event)"
        (totalCount)="setTotalCount($event)"
      >
        <ng-template #noRecordFoundTemplate>
          <div class="container">
            <div class="image-container">
              <img src="assets/icons/claim_empty_state.svg" alt="" />
            </div>
            <span class="empty-state-title">No Claims</span>
            <span class="empty-state-description">{{ emptyStateDescription }}</span>
            @if (hasCreateClaimPermission && !isGlobalClaimsList) {
              <div class="box">
                <p-button
                  class="primary-color add-task"
                  type="button"
                  class="add-task"
                  icon="pi pi-plus"
                  label="Add Claim"
                  [outlined]="false"
                  size="small"
                  (onClick)="navigateCreateClaim()"
                  (keyup.enter)="navigateCreateClaim()"
                  tabindex="0"
                />
              </div>
            }
          </div>
        </ng-template>
      </app-table>
    </ng-container>
  }
</div>
